'use strict';

function controller($scope, $rootScope, $http, $state, account) {
  $scope.account = account;
  $scope.currentUser = $rootScope.currentUser;

  $scope.delete = function () {
    $http
      .delete('/api/accounts/' + account.id + '.json')
      .then(function () {
        if ($scope.currentUser.isClient()) {
          return $state.reload();
        }
      })
      .then(function () {
        $scope.$close();
        window.location.reload();
      });
  };
}

angular
  .module('controller.account.delete', [])
  .controller('DeleteAccountCtrl', [
    '$scope',
    '$rootScope',
    '$http',
    '$state',
    'account',
    controller
  ]);
